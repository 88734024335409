<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
     id="sidebar">
  <div class="container-fluid">
    <!-- Toggler -->
    <button aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            data-target="#sidebarCollapse"
            data-toggle="collapse"
            (click)="menuOpened=!menuOpened"
            type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand mb-5"
       routerLink="/">
      <img alt="..."
           class="navbar-brand-img logo mx-lg-auto ml-auto"
           src="assets/images/logo.svg">
    </a>

    <!-- Collapse -->
    <div class="collapse navbar-collapse"
         [ngClass]="{'show': menuOpened}"
         id="sidebarCollapse">
      <!-- Navigation -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link"
             routerLinkActive="active"
             routerLink="/home">
            <i class="fe fe-command"></i> Home
          </a>
        </li>
      </ul>

      <div class="mt-auto"></div>
      <div class="navbar-user d-none d-md-flex"
           id="sidebarUser">
        <button (click)="signOut()"
                class="btn btn-link">
          <i class="fe fe-log-out"></i>
          Logout
        </button>
      </div>
      <span class="version text-center">{{version}}</span>
    </div> <!-- / .navbar-collapse -->
  </div>
</nav>
<div class="main-content">
  <router-outlet></router-outlet>
</div>
