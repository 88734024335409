<div class="c-auth-layout">
  <div class="container c-auth-layout__content">
    <div class="row">
      <div class="col-12 col-lg-4 mx-auto">
        <img src="assets/images/logo.svg" class="c-auth-layout__logo" alt="logo"/>
        <router-outlet></router-outlet>

      </div>
    </div>
  </div>
  <div class="c-auth-layout__copy">
      <strong>Watergate</strong> 2024
  </div>
</div>
