import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManufacturingReportsListComponent} from './manufacturing-reports-list/manufacturing-reports-list.component';
import {RouterModule} from '@angular/router';
import {LoaderComponent} from './loader/loader.component';
import { JsonDataTableComponent } from './json-data-table/json-data-table.component';


@NgModule({
  declarations: [
    ManufacturingReportsListComponent,
    LoaderComponent,
    JsonDataTableComponent,
  ],
  exports: [
    ManufacturingReportsListComponent,
    LoaderComponent,
    JsonDataTableComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class ComponentsModule {
}
