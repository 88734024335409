import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {UserResource} from '../resorces/user.resource';

@Injectable()
export class UserService {

  // @ts-ignore
  public user: User;

  constructor(private userResource: UserResource) {
  }

  public getUser(id: number): Observable<User> {
    return this.userResource.getUser(id);
  }
}
