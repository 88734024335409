import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserResource {

  private apiUrl = `${environment.apiGatewayUrl}`;

  constructor(private http: HttpClient) {
  }

  public getUser(id: number): Observable<User> {
    const url = `${this.apiUrl}/user/${id}`;

    return this.http.get<User>(url);
  }
}
