export class StorageParser {

  public static storageGet<T>(key: string): T {
    try {
      // @ts-ignore
      return JSON.parse(localStorage.getItem(key));
    } catch {
      // @ts-ignore
      return null;
    }
  }
}
