import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import {
  PressureReportResult,
  ProvisionReportResult,
} from 'src/app/models/manufacturing';
@Component({
  selector: 'app-json-data-table',
  templateUrl: './json-data-table.component.html',
  styleUrls: ['./json-data-table.component.scss'],
})
export class JsonDataTableComponent implements OnInit, OnChanges {
  @Output() subtableName = new EventEmitter<string>();
  @ViewChild('exportTable') exportTable: ElementRef;
  @Input() content: PressureReportResult | ProvisionReportResult;
  @Input() nestedJson = false;
  @Input() showSubtable: boolean;

  public serial: string;
  public viewType = 'table'; // 'table',  'json'
  public keys: string[];
  public values: any;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.keys = Object.keys(this.content);
    this.values = Object.values(this.content);
  }

  ngOnChanges() {
    this.keys = Object.keys(this.content);
    this.values = Object.values(this.content);
    this.cdRef.detectChanges();
  }
  public chaneView(newView: string) {
    this.viewType = newView;
  }

  public subtableToggle(event: string) {
    this.subtableName.emit(event);
  }

  public getObjectSize(data: any): number {
    return _.keys(data).length;
  }

  public isArray(data: any): boolean {
    return Array.isArray(data);
  }

  public isArrayOfObjects(data: any): boolean {
    return this.isArray(data) && data.length > 0 && typeof data[0] === 'object';
  }

  public isObject(data: any): boolean {
    return typeof data === 'object' && !Array.isArray(data);
  }

  public getColspanForData(data: any): number {
    if (this.isObject(data)) {
      return this.getObjectSize(data);
    }
    return 1;
  }

  public getDataSize(index: number): number {
    return _.max([
      this.getObjectSize(this.values[index]),
      this.getMinColSpanSize(this.keys[index]),
    ]) as number;
  }

  public getMinColSpanSize(key: string): number {
    switch (key) {
      case 'externalSupplyTest': case 'pairingTest':
        return 5;
      case 'batteryLevelTest': case 'buzzerTest': case 'pressureTest':
        return 4;
      case 'temperatureTest':
        return 7;
      case 'ultrasoundTest':
        return 8;
      case 'motorVoltageTest':
        return 9;
      case 'ledVoltageTest':
        return 12;
      case 'switchTest':
        return 3;
    }
    return 1;
  }

  public getDataClass(data: any): string {
    switch (data) {
      case 'ok':
      case 'true':
        return 'text-success cell-data';
    }
    if (typeof data === 'string' && data.indexOf('failed') > -1) {
      return 'text-danger cell-data';
    }
    return '';
  }
}
