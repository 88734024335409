<ul class="list-group">
  <ng-container *ngIf="!reports || reports.length===0; else hasReports">
    <li class="list-group-item text-center">
      No blocked items
      <ng-container *ngIf="foundCorrect"><br/>but found passed report</ng-container>
    </li>
  </ng-container>
  <ng-template #hasReports>
    <li class="list-group-item p-1" *ngFor="let report of reports">
      <button class="btn btn-block d-flex p-2"
              routerLink="/details/{{detailsName}}/{{report.serial_no}}">
        <span class="text-uppercase">{{ report.serial_no }}</span> <span
        class="badge badge-secondary ml-auto">{{ report.updated_at |date:'yyyy-MM-dd HH:mm' }}</span>
      </button>
    </li>
  </ng-template>
</ul>
