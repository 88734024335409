import {Injectable} from '@angular/core';
import {ManufacturingResource} from "../resorces/manufacturing.resource";
import {Observable} from "rxjs";
import {Pageable} from "../../models/base";
import {
  CalibrationResult,
  ManufacturingReport,
  PressureResult,
  ProvisionReport,
  ProvisionReportResult
} from "../../models/manufacturing";

@Injectable()
export class ManufacturingService {

  constructor(private resource: ManufacturingResource) {
  }

  public getCalibrationResults(params = {}): Observable<Pageable<CalibrationResult>> {
    return this.resource.getCalibrationResults(params);
  }

  public getProvisionReports(params = {}): Observable<Pageable<ProvisionReportResult>> {
    return this.resource.getProvisionReports(params);
  }

  public getPressureTestResults(params = {}): Observable<Pageable<PressureResult>> {
    return this.resource.getPressureTestResults(params);
  }


  public getCalibrationResultsBySerial(serialNo: string, params = {}): Observable<Pageable<CalibrationResult>> {
    return this.resource.getCalibrationResultsBySerial(serialNo, params);
  }

  public getProvisionReportsBySerial(serialNo: string, params = {}): Observable<Pageable<ProvisionReport>> {
    return this.resource.getProvisionReportsBySerial(serialNo, params);
  }

  public getPressureTestResultsBySerial(serialNo: string, params = {}): Observable<Pageable<PressureResult>> {
    return this.resource.getPressureTestResultsBySerial(serialNo, params);
  }

  public getManufacturingReports(params = {}): Observable<Pageable<ManufacturingReport>> {
    return this.resource.getManufacturingReports(params);
  }

  public updateManufacturingReports(serialNo: string, params = {}): Observable<ManufacturingReport> {
    return this.resource.updateManufacturingReports(serialNo, params);
  }

  public updateProvisionReport(serialNo: string, id: string, params = {}): Observable<ProvisionReport> {
    return this.resource.updateProvisionReport(serialNo, id, params);
  }

  public updateCalibrationResult(serialNo: string, id: string, params = {}): Observable<CalibrationResult> {
    return this.resource.updateCalibrationResult(serialNo, id, params);
  }

  public updatePressureTest(serialNo: string, id: string, params = {}): Observable<PressureResult> {
    return this.resource.updatePressureTest(serialNo, id, params);
  }


  public getManufacturingReportBySerial(serialNo: string): Observable<ManufacturingReport> {
    return this.resource.getManufacturingReportBySerial(serialNo);
  }
}
