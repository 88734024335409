import {Injectable} from '@angular/core';
import {RefreshResponse, SignInResponse} from '../../models/auth';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class AuthResource {

  private apiUrl = `${environment.apiGatewayUrl}/user-service/auth`;

  constructor(private http: HttpClient) {
  }

  public signIn(email: string, password: string): Observable<SignInResponse> {
    const url = `${this.apiUrl}/sign_in`;

    return this.http.post<SignInResponse>(url, {
      email: email,
      password: password,
    });
  }

  public refreshToken(): Observable<RefreshResponse> {
    const url = `${this.apiUrl}/refresh_token`;

    return this.http.put<RefreshResponse>(url, null);
  }
}
