import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../providers/services/auth.service';
import * as packageJson from '../../../../package.json';

@Component({
  selector: 'app-layout-app',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  public version: string;
  public menuOpened = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    const appInfo = packageJson;
    this.version = appInfo.version;
  }

  public signOut() {
    this.authService.signOut();
  }
}
