import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pageable} from "../../models/base";
import {
  CalibrationResult,
  ManufacturingReport,
  PressureResult,
  ProvisionReport,
  ProvisionReportResult
} from "../../models/manufacturing";

@Injectable()
export class ManufacturingResource {

  private readonly url: string = `${environment.apiGatewayUrl}/new-device-provisioner/v1`;

  constructor(private http: HttpClient) {
  }

  public getCalibrationResults(params = {}): Observable<Pageable<CalibrationResult>> {
    const url = `${this.url}/calibration_results`;
    return this.http.get<Pageable<CalibrationResult>>(url, {params});
  }

  public getProvisionReports(params = {}): Observable<Pageable<ProvisionReportResult>> {
    const url = `${this.url}/provision_reports`;
    return this.http.get<Pageable<ProvisionReportResult>>(url, {params});
  }

  public getPressureTestResults(params = {}): Observable<Pageable<PressureResult>> {
    const url = `${this.url}/pressure_tests`;
    return this.http.get<Pageable<PressureResult>>(url, {params});
  }


  public getCalibrationResultsBySerial(serialNo: string, params = {}): Observable<Pageable<CalibrationResult>> {
    const url = `${this.url}/devices/${serialNo}/calibration_results`;
    return this.http.get<Pageable<CalibrationResult>>(url, {params});
  }

  public getProvisionReportsBySerial(serialNo: string, params = {}): Observable<Pageable<ProvisionReport>> {
    const url = `${this.url}/devices/${serialNo}/provision_reports`;
    return this.http.get<Pageable<ProvisionReport>>(url, {params});
  }

  public updateProvisionReport(serialNo: string, id: string, params = {}): Observable<ProvisionReport> {
    const url = `${this.url}/devices/${serialNo}/provision_reports/${id}`;
    return this.http.put<ProvisionReport>(url, params);
  }

  public updateCalibrationResult(serialNo: string, id: string, params = {}): Observable<CalibrationResult> {
    const url = `${this.url}/devices/${serialNo}/calibration_results/${id}`;
    return this.http.put<CalibrationResult>(url, params);
  }

  public updatePressureTest(serialNo: string, id: string, params = {}): Observable<PressureResult> {
    const url = `${this.url}/devices/${serialNo}/pressure_tests/${id}`;
    return this.http.put<PressureResult>(url, params);
  }

  public getPressureTestResultsBySerial(serialNo: string, params = {}): Observable<Pageable<PressureResult>> {
    const url = `${this.url}/devices/${serialNo}/pressure_tests`;
    return this.http.get<Pageable<PressureResult>>(url, {params});
  }


  public getManufacturingReports(params = {}): Observable<Pageable<ManufacturingReport>> {
    const url = `${this.url}/manufacturing_reports`;
    return this.http.get<Pageable<ManufacturingReport>>(url, {params});
  }

  public updateManufacturingReports(serialNo: string, params = {}): Observable<ManufacturingReport> {
    const url = `${this.url}/devices/${serialNo}/manufacturing_report`;
    return this.http.put<ManufacturingReport>(url, params);
  }

  public getManufacturingReportBySerial(serialNo: string): Observable<ManufacturingReport> {
    const url = `${this.url}/devices/${serialNo}/manufacturing_report`;
    return this.http.get<ManufacturingReport>(url);
  }
}
