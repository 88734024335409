import {Injectable} from '@angular/core';
import {RefreshResponse, SignInResponse} from '../../models/auth';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {AuthResource} from '../resorces/auth.resource';
import {UserService} from './user.service';
import {StorageParser} from '../../common/storage/StorageParser';
import {User} from '../../models/user';
import * as _ from 'lodash';

@Injectable()
export class AuthService {

  public authenticationState = new BehaviorSubject<boolean | null>(null);
  public redirectUrl: string;
  private readonly TOKEN_KEY = 'auth-token';
  private readonly USER_KEY = 'user';

  constructor(private authResource: AuthResource,
              private userService: UserService) {
  }

  public signIn(email: string, password: string): Observable<SignInResponse> {
    return this.authResource.signIn(email, password)
      .pipe(tap(response => {
        this.processLogin(response);
      }));
  }

  public checkToken() {
    const user = StorageParser.storageGet<User>(this.USER_KEY);
    const token = localStorage.getItem(this.TOKEN_KEY);
    if (!user || !token) {
      this.authenticationState.next(false);
      return;
    }
    this.userService.user = user;
    this.refreshToken()
      .subscribe();
  }

  public refreshToken(): Observable<RefreshResponse> {
    return this.authResource.refreshToken()
      .pipe(tap({
        next: (response: RefreshResponse) => {
          this.processRefresh(response);
        },
        error: (error) => {
          this.authenticationState.next(false);
        }
      }))
  }

  public getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public signOut(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    this.authenticationState.next(false);
  }

  private processLogin(response: SignInResponse): void {

    if (_.find(response.user_details?.roles, (r) => r.name === 'manufacturer')) {
      this.storeToken(response.token_details);
      localStorage.setItem(this.USER_KEY, JSON.stringify(response.user_details));
      this.userService.user = response.user_details;
      this.authenticationState.next(true);
    } else {
      throw Error('You have not sufficient permission');
    }
  }

  private processRefresh(response: RefreshResponse): void {
    this.storeToken(response.token_details);
    this.authenticationState.next(true);
  }

  private storeToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }


}
