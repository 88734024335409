import {Injectable} from '@angular/core';
import {Observable, skipWhile} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public authService: AuthService,
              public router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authenticationState.asObservable()
      .pipe(
        skipWhile(state => state === null),
        map((state) => {
          if (!state) {
            this.authService.redirectUrl = routerState.url
            this.router.navigate(['auth/login'])
              .then(() => console.warn('Can not activate route: ' + route.url));
          }
          return state === true;
        })
      );
  }
}
