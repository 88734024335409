import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './services/auth.service';
import {AuthResource} from './resorces/auth.resource';
import {AuthGuardService} from './services/auth-guard.service';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {UserService} from './services/user.service';
import {UserResource} from './resorces/user.resource';
import {ManufacturingResource} from "./resorces/manufacturing.resource";
import {ManufacturingService} from "./services/manufacturing.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AuthGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    AuthService,
    AuthResource,
    UserService,
    UserResource,
    ManufacturingResource,
    ManufacturingService,
  ]
})
export class ProvidersModule {
}
