import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ManufacturingService} from "../../providers/services/manufacturing.service";
import * as _ from 'lodash';
import {ManufacturingReport} from "../../models/manufacturing";
import {Subject} from "rxjs";

@Component({
  selector: 'app-manufacturing-reports-list',
  templateUrl: './manufacturing-reports-list.component.html',
  styleUrls: ['./manufacturing-reports-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManufacturingReportsListComponent implements OnInit {

  @Input() searchParams: { [key: string]: any };
  @Input() detailsName: string;
  @Input() serialSearchSubject: Subject<string>;

  public reports: ManufacturingReport[] = [];
  public foundCorrect = false;

  constructor(private manufacturingService: ManufacturingService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.fetchPage();
    this.serialSearchSubject.asObservable()
      .subscribe((serial) => {
        if (_.isNull(serial)) {
          return;
        }
        if (serial === '') {
          this.fetchPage();
        } else {
          this.fetchBySerial(serial);
        }
      });
  }

  private fetchPage(params = {page_size: 30}) {
    _.each(this.searchParams, (value, key) => {
      _.set(params, key, value);
    });
    this.foundCorrect = false;
    this.manufacturingService.getManufacturingReports(params)
      .subscribe({
        next: (result) => {
          this.reports = result.data;
          this.cdRef.detectChanges();
        }, error: () => {
          this.reports = [];
          this.cdRef.detectChanges();
        }
      });
  }

  private fetchBySerial(serial: string) {
    this.manufacturingService.getManufacturingReportBySerial(serial)
      .subscribe({
        next: report => {
          const isBlocked = this.isBlocked(report);
          this.foundCorrect = !isBlocked;

          this.reports = isBlocked ? [report] : [];
          this.cdRef.detectChanges();
        }, error: () => {
          this.reports = [];
          this.cdRef.detectChanges();
        }
      });
  }

  private isBlocked(report: ManufacturingReport): boolean {
    let blocked = true;
    _.forEach(this.searchParams, (value, key) => {
      if (_.get(report, key) !== value) {
        blocked = false;
        return;
      }
    });
    return blocked;
  }
}
