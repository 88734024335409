import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './providers/services/auth-guard.service';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {AppLayoutComponent} from './layouts/app/app-layout.component';


const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    component: AppLayoutComponent,
    loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
  }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
