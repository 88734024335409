<div class="c-json-data__buttons-posiotion">
  <button
    *ngIf="viewType !== 'table'"
    type="button"
    class="btn btn-sm btn-secondary btn-sm ml-2"
    (click)="chaneView('table')"
  >
    Table
  </button>
  <button
    *ngIf="viewType !== 'json'"
    type="button"
    class="btn btn-sm btn-secondary btn-sm ml-2"
    (click)="chaneView('json')"
  >
    Json
  </button>
</div>
<ng-container [ngSwitch]="viewType">
  <div *ngSwitchCase="'table'">
    <table
      class="table table-responsive result-table table-bordered"
      *ngIf="!nestedJson; else nestedJsonTable"
    >

      <tbody *ngIf="showSubtable; else standardTable"
      >
        <tr *ngFor="let data of keys; let i = index"
            (click)="subtableToggle(data)"
            class="c-json-data__table-row">
          <th>
            <span [ngClass]="getDataClass(data)">
              {{ data }}
            </span>
          </th>
          <td>
            <ng-container
              *ngTemplateOutlet="displayValue; context: { data: values[i] }"
            ></ng-container>
          </td>
        </tr>
      </tbody>

      <ng-template #standardTable>
      <tbody>
        <tr *ngFor="let data of keys; let i = index">
          <th>
            <a
              [ngClass]="getDataClass(data)"
            >
              {{ data }}
            </a>
            <ng-template>
              <span [ngClass]="getDataClass(data)">
                {{ data }}
              </span>
            </ng-template>
          </th>
          <td>
            <ng-container
              *ngTemplateOutlet="displayValue; context: { data: values[i] }"
            ></ng-container>
          </td>
        </tr>
      </tbody>
     </ng-template>

    </table>
    <ng-template #nestedJsonTable>
      <table
        class="table table-responsive result-table table-bordered table-striped"
      >
        <tbody>
          <ng-container *ngFor="let data of values; let i = index">
            <tr *ngFor="let item of data | keyvalue; let first = first">
              <th
                *ngIf="first"
                [rowSpan]="getColspanForData(data)"
                class="c-json-data__bg-none"
                scope="row"
              >
                {{ keys[i] }}
              </th>
              <td class="c-json-data__row-title">
                {{ item.key }}
              </td>
              <td class="c-json-data__nested-td">
                <ng-container
                  *ngTemplateOutlet="
                    displayValue;
                    context: { data: item.value }
                  "
                ></ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div *ngSwitchCase="'json'">
    <pre>
          <p> {{content | json}} </p>
          </pre>
  </div>
</ng-container>

<ng-template #simpleValue let-data="data">
  <span [ngClass]="getDataClass(data)">
    {{ data }}
  </span>
</ng-template>
<ng-template #objectValue let-data="data">
  <ng-container *ngFor="let dataItem of data | keyvalue">
    {{ dataItem.key }}: {{ dataItem.value }}
  </ng-container>
</ng-template>
<ng-template #arrayValue let-data="data">
  <ng-container *ngFor="let objectData of data; let last = last">
    <ng-container
      *ngTemplateOutlet="displayValue; context: { data: objectData }"
    ></ng-container>
    <span *ngIf="!last">;<br /></span>
  </ng-container>
</ng-template>
<ng-template #displayValue let-data="data">
  <ng-container *ngIf="isArrayOfObjects(data); else notArray">
    <ng-container
      *ngTemplateOutlet="arrayValue; context: { data: data }"
    ></ng-container>
  </ng-container>
  <ng-template #notArray>
    <ng-container
      [ngTemplateOutlet]="isObject(data) ? objectValue : simpleValue"
      [ngTemplateOutletContext]="{ data: data }"
    ></ng-container>
  </ng-template>
</ng-template>
